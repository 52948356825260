
/* eslint-disable */
import { languageTag } from "./runtime.js"
import * as en from "./messages/en.js"
import * as fr from "./messages/fr.js"

/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ projectTitle: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activitiesNewMessageSubitle = (params , options = {}) => {
	return {
		en: en.activitiesNewMessageSubitle,
		fr: fr.activitiesNewMessageSubitle
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ projectTitle: NonNullable<unknown>, productTitle: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activitiesNewMessageSubitleWithProduct = (params , options = {}) => {
	return {
		en: en.activitiesNewMessageSubitleWithProduct,
		fr: fr.activitiesNewMessageSubitleWithProduct
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ userName: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activitiesNewMessageTitle = (params , options = {}) => {
	return {
		en: en.activitiesNewMessageTitle,
		fr: fr.activitiesNewMessageTitle
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activitiesNoActivities = (params = {}, options = {}) => {
	return {
		en: en.activitiesNoActivities,
		fr: fr.activitiesNoActivities
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activitiesNoActivitiesDescription = (params = {}, options = {}) => {
	return {
		en: en.activitiesNoActivitiesDescription,
		fr: fr.activitiesNoActivitiesDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activitiesNoActivitiesTitle = (params = {}, options = {}) => {
	return {
		en: en.activitiesNoActivitiesTitle,
		fr: fr.activitiesNoActivitiesTitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const areas = (params = {}, options = {}) => {
	return {
		en: en.areas,
		fr: fr.areas
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const chatNotFound = (params = {}, options = {}) => {
	return {
		en: en.chatNotFound,
		fr: fr.chatNotFound
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const companySettings = (params = {}, options = {}) => {
	return {
		en: en.companySettings,
		fr: fr.companySettings
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasAttic = (params = {}, options = {}) => {
	return {
		en: en.defaultAreasAttic,
		fr: fr.defaultAreasAttic
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasBasement = (params = {}, options = {}) => {
	return {
		en: en.defaultAreasBasement,
		fr: fr.defaultAreasBasement
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasBathroom = (params = {}, options = {}) => {
	return {
		en: en.defaultAreasBathroom,
		fr: fr.defaultAreasBathroom
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasBedroom = (params = {}, options = {}) => {
	return {
		en: en.defaultAreasBedroom,
		fr: fr.defaultAreasBedroom
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasCraftRoom = (params = {}, options = {}) => {
	return {
		en: en.defaultAreasCraftRoom,
		fr: fr.defaultAreasCraftRoom
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasDiningRoom = (params = {}, options = {}) => {
	return {
		en: en.defaultAreasDiningRoom,
		fr: fr.defaultAreasDiningRoom
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasFoyer = (params = {}, options = {}) => {
	return {
		en: en.defaultAreasFoyer,
		fr: fr.defaultAreasFoyer
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasGameRoom = (params = {}, options = {}) => {
	return {
		en: en.defaultAreasGameRoom,
		fr: fr.defaultAreasGameRoom
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasGarage = (params = {}, options = {}) => {
	return {
		en: en.defaultAreasGarage,
		fr: fr.defaultAreasGarage
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasGarden = (params = {}, options = {}) => {
	return {
		en: en.defaultAreasGarden,
		fr: fr.defaultAreasGarden
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasGuestRoom = (params = {}, options = {}) => {
	return {
		en: en.defaultAreasGuestRoom,
		fr: fr.defaultAreasGuestRoom
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasGym = (params = {}, options = {}) => {
	return {
		en: en.defaultAreasGym,
		fr: fr.defaultAreasGym
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasHomeOffice = (params = {}, options = {}) => {
	return {
		en: en.defaultAreasHomeOffice,
		fr: fr.defaultAreasHomeOffice
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasHomeTheater = (params = {}, options = {}) => {
	return {
		en: en.defaultAreasHomeTheater,
		fr: fr.defaultAreasHomeTheater
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasKitchen = (params = {}, options = {}) => {
	return {
		en: en.defaultAreasKitchen,
		fr: fr.defaultAreasKitchen
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasLaundryRoom = (params = {}, options = {}) => {
	return {
		en: en.defaultAreasLaundryRoom,
		fr: fr.defaultAreasLaundryRoom
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasLibrary = (params = {}, options = {}) => {
	return {
		en: en.defaultAreasLibrary,
		fr: fr.defaultAreasLibrary
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasLivingRoom = (params = {}, options = {}) => {
	return {
		en: en.defaultAreasLivingRoom,
		fr: fr.defaultAreasLivingRoom
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasMasterBedroom = (params = {}, options = {}) => {
	return {
		en: en.defaultAreasMasterBedroom,
		fr: fr.defaultAreasMasterBedroom
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasMudroom = (params = {}, options = {}) => {
	return {
		en: en.defaultAreasMudroom,
		fr: fr.defaultAreasMudroom
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasPantry = (params = {}, options = {}) => {
	return {
		en: en.defaultAreasPantry,
		fr: fr.defaultAreasPantry
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasPatio = (params = {}, options = {}) => {
	return {
		en: en.defaultAreasPatio,
		fr: fr.defaultAreasPatio
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasSunroom = (params = {}, options = {}) => {
	return {
		en: en.defaultAreasSunroom,
		fr: fr.defaultAreasSunroom
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasSwimingPool = (params = {}, options = {}) => {
	return {
		en: en.defaultAreasSwimingPool,
		fr: fr.defaultAreasSwimingPool
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasTerrace = (params = {}, options = {}) => {
	return {
		en: en.defaultAreasTerrace,
		fr: fr.defaultAreasTerrace
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasWineCellar = (params = {}, options = {}) => {
	return {
		en: en.defaultAreasWineCellar,
		fr: fr.defaultAreasWineCellar
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const homepageTagline = (params = {}, options = {}) => {
	return {
		en: en.homepageTagline,
		fr: fr.homepageTagline
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const logout = (params = {}, options = {}) => {
	return {
		en: en.logout,
		fr: fr.logout
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const newProject = (params = {}, options = {}) => {
	return {
		en: en.newProject,
		fr: fr.newProject
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const members = (params = {}, options = {}) => {
	return {
		en: en.members,
		fr: fr.members
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productNotFound = (params = {}, options = {}) => {
	return {
		en: en.productNotFound,
		fr: fr.productNotFound
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsBatchUpdate = (params = {}, options = {}) => {
	return {
		en: en.productsBatchUpdate,
		fr: fr.productsBatchUpdate
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsBatchUpdateModalClose = (params = {}, options = {}) => {
	return {
		en: en.productsBatchUpdateModalClose,
		fr: fr.productsBatchUpdateModalClose
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsBatchUpdateModalCta = (params = {}, options = {}) => {
	return {
		en: en.productsBatchUpdateModalCta,
		fr: fr.productsBatchUpdateModalCta
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsBatchUpdateModalTitle = (params = {}, options = {}) => {
	return {
		en: en.productsBatchUpdateModalTitle,
		fr: fr.productsBatchUpdateModalTitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ count: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsBatchUpdateModalWarning = (params , options = {}) => {
	return {
		en: en.productsBatchUpdateModalWarning,
		fr: fr.productsBatchUpdateModalWarning
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsCount = (params = {}, options = {}) => {
	return {
		en: en.productsCount,
		fr: fr.productsCount
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsEditArea = (params = {}, options = {}) => {
	return {
		en: en.productsEditArea,
		fr: fr.productsEditArea
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsEditState = (params = {}, options = {}) => {
	return {
		en: en.productsEditState,
		fr: fr.productsEditState
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsEditTypes = (params = {}, options = {}) => {
	return {
		en: en.productsEditTypes,
		fr: fr.productsEditTypes
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsEmptyClearFilters = (params = {}, options = {}) => {
	return {
		en: en.productsEmptyClearFilters,
		fr: fr.productsEmptyClearFilters
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsEmptyImageAlt = (params = {}, options = {}) => {
	return {
		en: en.productsEmptyImageAlt,
		fr: fr.productsEmptyImageAlt
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsEmptySubtitle = (params = {}, options = {}) => {
	return {
		en: en.productsEmptySubtitle,
		fr: fr.productsEmptySubtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsEmptyTitle = (params = {}, options = {}) => {
	return {
		en: en.productsEmptyTitle,
		fr: fr.productsEmptyTitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsExport = (params = {}, options = {}) => {
	return {
		en: en.productsExport,
		fr: fr.productsExport
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsExportInvoice = (params = {}, options = {}) => {
	return {
		en: en.productsExportInvoice,
		fr: fr.productsExportInvoice
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsExportPurchaseOrder = (params = {}, options = {}) => {
	return {
		en: en.productsExportPurchaseOrder,
		fr: fr.productsExportPurchaseOrder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsFiltersClearAll = (params = {}, options = {}) => {
	return {
		en: en.productsFiltersClearAll,
		fr: fr.productsFiltersClearAll
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsSearchByTitle = (params = {}, options = {}) => {
	return {
		en: en.productsSearchByTitle,
		fr: fr.productsSearchByTitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsSelectAll = (params = {}, options = {}) => {
	return {
		en: en.productsSelectAll,
		fr: fr.productsSelectAll
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStatesDisliked = (params = {}, options = {}) => {
	return {
		en: en.productStatesDisliked,
		fr: fr.productStatesDisliked
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStatesInstalled = (params = {}, options = {}) => {
	return {
		en: en.productStatesInstalled,
		fr: fr.productStatesInstalled
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStatesLiked = (params = {}, options = {}) => {
	return {
		en: en.productStatesLiked,
		fr: fr.productStatesLiked
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStatesOrdered = (params = {}, options = {}) => {
	return {
		en: en.productStatesOrdered,
		fr: fr.productStatesOrdered
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStatesProposed = (params = {}, options = {}) => {
	return {
		en: en.productStatesProposed,
		fr: fr.productStatesProposed
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStatesReceived = (params = {}, options = {}) => {
	return {
		en: en.productStatesReceived,
		fr: fr.productStatesReceived
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStatesShipped = (params = {}, options = {}) => {
	return {
		en: en.productStatesShipped,
		fr: fr.productStatesShipped
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStatesUnspecified = (params = {}, options = {}) => {
	return {
		en: en.productStatesUnspecified,
		fr: fr.productStatesUnspecified
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStatesValidated = (params = {}, options = {}) => {
	return {
		en: en.productStatesValidated,
		fr: fr.productStatesValidated
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productTypesEmpty = (params = {}, options = {}) => {
	return {
		en: en.productTypesEmpty,
		fr: fr.productTypesEmpty
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productTypesPlaceholder = (params = {}, options = {}) => {
	return {
		en: en.productTypesPlaceholder,
		fr: fr.productTypesPlaceholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const projectNavChat = (params = {}, options = {}) => {
	return {
		en: en.projectNavChat,
		fr: fr.projectNavChat
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const projectNavProducts = (params = {}, options = {}) => {
	return {
		en: en.projectNavProducts,
		fr: fr.projectNavProducts
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const projectNavSettings = (params = {}, options = {}) => {
	return {
		en: en.projectNavSettings,
		fr: fr.projectNavSettings
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const projectNewDescriptionLabel = (params = {}, options = {}) => {
	return {
		en: en.projectNewDescriptionLabel,
		fr: fr.projectNewDescriptionLabel
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const projectNewSubmit = (params = {}, options = {}) => {
	return {
		en: en.projectNewSubmit,
		fr: fr.projectNewSubmit
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const projectNewTitleLabel = (params = {}, options = {}) => {
	return {
		en: en.projectNewTitleLabel,
		fr: fr.projectNewTitleLabel
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const projectEditSubmit = (params = {}, options = {}) => {
	return {
		en: en.projectEditSubmit,
		fr: fr.projectEditSubmit
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const projectNotFound = (params = {}, options = {}) => {
	return {
		en: en.projectNotFound,
		fr: fr.projectNotFound
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const projects = (params = {}, options = {}) => {
	return {
		en: en.projects,
		fr: fr.projects
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signIn = (params = {}, options = {}) => {
	return {
		en: en.signIn,
		fr: fr.signIn
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signInTitle = (params = {}, options = {}) => {
	return {
		en: en.signInTitle,
		fr: fr.signInTitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signInEmail = (params = {}, options = {}) => {
	return {
		en: en.signInEmail,
		fr: fr.signInEmail
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signInPassword = (params = {}, options = {}) => {
	return {
		en: en.signInPassword,
		fr: fr.signInPassword
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signInForgotPassword = (params = {}, options = {}) => {
	return {
		en: en.signInForgotPassword,
		fr: fr.signInForgotPassword
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signUp = (params = {}, options = {}) => {
	return {
		en: en.signUp,
		fr: fr.signUp
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signUpFirstName = (params = {}, options = {}) => {
	return {
		en: en.signUpFirstName,
		fr: fr.signUpFirstName
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signUpLastName = (params = {}, options = {}) => {
	return {
		en: en.signUpLastName,
		fr: fr.signUpLastName
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signUpEmail = (params = {}, options = {}) => {
	return {
		en: en.signUpEmail,
		fr: fr.signUpEmail
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signUpPassword = (params = {}, options = {}) => {
	return {
		en: en.signUpPassword,
		fr: fr.signUpPassword
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signUpPasswordConfirmation = (params = {}, options = {}) => {
	return {
		en: en.signUpPasswordConfirmation,
		fr: fr.signUpPasswordConfirmation
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sortedBy = (params = {}, options = {}) => {
	return {
		en: en.sortedBy,
		fr: fr.sortedBy
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sortedByArea = (params = {}, options = {}) => {
	return {
		en: en.sortedByArea,
		fr: fr.sortedByArea
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sortedByCreatedAt = (params = {}, options = {}) => {
	return {
		en: en.sortedByCreatedAt,
		fr: fr.sortedByCreatedAt
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sortedByState = (params = {}, options = {}) => {
	return {
		en: en.sortedByState,
		fr: fr.sortedByState
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const states = (params = {}, options = {}) => {
	return {
		en: en.states,
		fr: fr.states
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const types = (params = {}, options = {}) => {
	return {
		en: en.types,
		fr: fr.types
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const userSettings = (params = {}, options = {}) => {
	return {
		en: en.userSettings,
		fr: fr.userSettings
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const large_polite_gibbon_relish = (params = {}, options = {}) => {
	return {
		en: en.large_polite_gibbon_relish,
		fr: fr.large_polite_gibbon_relish
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const dull_that_mole_grip = (params = {}, options = {}) => {
	return {
		en: en.dull_that_mole_grip,
		fr: fr.dull_that_mole_grip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const wacky_bold_fireant_mop = (params = {}, options = {}) => {
	return {
		en: en.wacky_bold_fireant_mop,
		fr: fr.wacky_bold_fireant_mop
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const big_known_anteater_snap = (params = {}, options = {}) => {
	return {
		en: en.big_known_anteater_snap,
		fr: fr.big_known_anteater_snap
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lost_away_finch_dine = (params = {}, options = {}) => {
	return {
		en: en.lost_away_finch_dine,
		fr: fr.lost_away_finch_dine
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const calm_strong_crow_read = (params = {}, options = {}) => {
	return {
		en: en.calm_strong_crow_read,
		fr: fr.calm_strong_crow_read
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const petty_bright_dog_compose = (params = {}, options = {}) => {
	return {
		en: en.petty_bright_dog_compose,
		fr: fr.petty_bright_dog_compose
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const chunky_light_skunk_hike = (params = {}, options = {}) => {
	return {
		en: en.chunky_light_skunk_hike,
		fr: fr.chunky_light_skunk_hike
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const topical_frail_polecat_dial = (params = {}, options = {}) => {
	return {
		en: en.topical_frail_polecat_dial,
		fr: fr.topical_frail_polecat_dial
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tasty_drab_lion_beam = (params = {}, options = {}) => {
	return {
		en: en.tasty_drab_lion_beam,
		fr: fr.tasty_drab_lion_beam
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const left_vexed_ocelot_dial = (params = {}, options = {}) => {
	return {
		en: en.left_vexed_ocelot_dial,
		fr: fr.left_vexed_ocelot_dial
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fresh_icy_nuthatch_cuddle = (params = {}, options = {}) => {
	return {
		en: en.fresh_icy_nuthatch_cuddle,
		fr: fr.fresh_icy_nuthatch_cuddle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fun_upper_cobra_type = (params = {}, options = {}) => {
	return {
		en: en.fun_upper_cobra_type,
		fr: fr.fun_upper_cobra_type
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const free_this_dragonfly_gulp = (params = {}, options = {}) => {
	return {
		en: en.free_this_dragonfly_gulp,
		fr: fr.free_this_dragonfly_gulp
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const basic_still_lionfish_pull = (params = {}, options = {}) => {
	return {
		en: en.basic_still_lionfish_pull,
		fr: fr.basic_still_lionfish_pull
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const curly_drab_niklas_fold = (params = {}, options = {}) => {
	return {
		en: en.curly_drab_niklas_fold,
		fr: fr.curly_drab_niklas_fold
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const such_muddy_tapir_hike = (params = {}, options = {}) => {
	return {
		en: en.such_muddy_tapir_hike,
		fr: fr.such_muddy_tapir_hike
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const zany_few_bee_ask = (params = {}, options = {}) => {
	return {
		en: en.zany_few_bee_ask,
		fr: fr.zany_few_bee_ask
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cozy_topical_finch_urge = (params = {}, options = {}) => {
	return {
		en: en.cozy_topical_finch_urge,
		fr: fr.cozy_topical_finch_urge
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_plane_koala_quell = (params = {}, options = {}) => {
	return {
		en: en.home_plane_koala_quell,
		fr: fr.home_plane_koala_quell
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const merry_brief_dachshund_pinch = (params = {}, options = {}) => {
	return {
		en: en.merry_brief_dachshund_pinch,
		fr: fr.merry_brief_dachshund_pinch
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const extra_male_anaconda_treat = (params = {}, options = {}) => {
	return {
		en: en.extra_male_anaconda_treat,
		fr: fr.extra_male_anaconda_treat
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const vexed_noisy_vulture_hurl = (params = {}, options = {}) => {
	return {
		en: en.vexed_noisy_vulture_hurl,
		fr: fr.vexed_noisy_vulture_hurl
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const direct_ago_thrush_believe = (params = {}, options = {}) => {
	return {
		en: en.direct_ago_thrush_believe,
		fr: fr.direct_ago_thrush_believe
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bold_home_iguana_revive = (params = {}, options = {}) => {
	return {
		en: en.bold_home_iguana_revive,
		fr: fr.bold_home_iguana_revive
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const deft_sad_loris_roar = (params = {}, options = {}) => {
	return {
		en: en.deft_sad_loris_roar,
		fr: fr.deft_sad_loris_roar
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tiny_smart_stork_splash = (params = {}, options = {}) => {
	return {
		en: en.tiny_smart_stork_splash,
		fr: fr.tiny_smart_stork_splash
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const due_lazy_pug_heart = (params = {}, options = {}) => {
	return {
		en: en.due_lazy_pug_heart,
		fr: fr.due_lazy_pug_heart
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const large_bald_duck_spin = (params = {}, options = {}) => {
	return {
		en: en.large_bald_duck_spin,
		fr: fr.large_bald_duck_spin
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loud_main_poodle_dig = (params = {}, options = {}) => {
	return {
		en: en.loud_main_poodle_dig,
		fr: fr.loud_main_poodle_dig
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const keen_ago_cockroach_aid = (params = {}, options = {}) => {
	return {
		en: en.keen_ago_cockroach_aid,
		fr: fr.keen_ago_cockroach_aid
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const hour_legal_firefox_zip = (params = {}, options = {}) => {
	return {
		en: en.hour_legal_firefox_zip,
		fr: fr.hour_legal_firefox_zip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sad_clear_otter_feast = (params = {}, options = {}) => {
	return {
		en: en.sad_clear_otter_feast,
		fr: fr.sad_clear_otter_feast
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const crazy_bald_bear_ask = (params = {}, options = {}) => {
	return {
		en: en.crazy_bald_bear_ask,
		fr: fr.crazy_bald_bear_ask
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const antsy_helpful_mayfly_treasure = (params = {}, options = {}) => {
	return {
		en: en.antsy_helpful_mayfly_treasure,
		fr: fr.antsy_helpful_mayfly_treasure
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const large_steep_jaguar_push = (params = {}, options = {}) => {
	return {
		en: en.large_steep_jaguar_push,
		fr: fr.large_steep_jaguar_push
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bald_steep_dingo_belong = (params = {}, options = {}) => {
	return {
		en: en.bald_steep_dingo_belong,
		fr: fr.bald_steep_dingo_belong
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const low_sour_tuna_ripple = (params = {}, options = {}) => {
	return {
		en: en.low_sour_tuna_ripple,
		fr: fr.low_sour_tuna_ripple
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tangy_quaint_shrike_dare = (params = {}, options = {}) => {
	return {
		en: en.tangy_quaint_shrike_dare,
		fr: fr.tangy_quaint_shrike_dare
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const glad_sharp_cobra_flop = (params = {}, options = {}) => {
	return {
		en: en.glad_sharp_cobra_flop,
		fr: fr.glad_sharp_cobra_flop
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const slimy_tasty_goose_care = (params = {}, options = {}) => {
	return {
		en: en.slimy_tasty_goose_care,
		fr: fr.slimy_tasty_goose_care
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pink_stale_frog_race = (params = {}, options = {}) => {
	return {
		en: en.pink_stale_frog_race,
		fr: fr.pink_stale_frog_race
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const close_good_lobster_achieve = (params = {}, options = {}) => {
	return {
		en: en.close_good_lobster_achieve,
		fr: fr.close_good_lobster_achieve
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const north_main_ox_ripple = (params = {}, options = {}) => {
	return {
		en: en.north_main_ox_ripple,
		fr: fr.north_main_ox_ripple
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const equal_home_hyena_harbor = (params = {}, options = {}) => {
	return {
		en: en.equal_home_hyena_harbor,
		fr: fr.equal_home_hyena_harbor
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const jolly_still_sloth_smile = (params = {}, options = {}) => {
	return {
		en: en.jolly_still_sloth_smile,
		fr: fr.jolly_still_sloth_smile
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const major_extra_camel_dare = (params = {}, options = {}) => {
	return {
		en: en.major_extra_camel_dare,
		fr: fr.major_extra_camel_dare
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const away_shy_jaguar_jolt = (params = {}, options = {}) => {
	return {
		en: en.away_shy_jaguar_jolt,
		fr: fr.away_shy_jaguar_jolt
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const that_flaky_pelican_cheer = (params = {}, options = {}) => {
	return {
		en: en.that_flaky_pelican_cheer,
		fr: fr.that_flaky_pelican_cheer
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const patient_next_hyena_win = (params = {}, options = {}) => {
	return {
		en: en.patient_next_hyena_win,
		fr: fr.patient_next_hyena_win
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const new_true_rooster_attend = (params = {}, options = {}) => {
	return {
		en: en.new_true_rooster_attend,
		fr: fr.new_true_rooster_attend
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const soft_brave_herring_splash = (params = {}, options = {}) => {
	return {
		en: en.soft_brave_herring_splash,
		fr: fr.soft_brave_herring_splash
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const alive_zippy_kestrel_flow = (params = {}, options = {}) => {
	return {
		en: en.alive_zippy_kestrel_flow,
		fr: fr.alive_zippy_kestrel_flow
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const raw_tiny_husky_fry = (params = {}, options = {}) => {
	return {
		en: en.raw_tiny_husky_fry,
		fr: fr.raw_tiny_husky_fry
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const polite_wild_mole_slide = (params = {}, options = {}) => {
	return {
		en: en.polite_wild_mole_slide,
		fr: fr.polite_wild_mole_slide
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const topical_loved_kangaroo_offer = (params = {}, options = {}) => {
	return {
		en: en.topical_loved_kangaroo_offer,
		fr: fr.topical_loved_kangaroo_offer
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const such_trite_rat_slurp = (params = {}, options = {}) => {
	return {
		en: en.such_trite_rat_slurp,
		fr: fr.such_trite_rat_slurp
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const clean_teal_toucan_zap = (params = {}, options = {}) => {
	return {
		en: en.clean_teal_toucan_zap,
		fr: fr.clean_teal_toucan_zap
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const clean_solid_polecat_grace = (params = {}, options = {}) => {
	return {
		en: en.clean_solid_polecat_grace,
		fr: fr.clean_solid_polecat_grace
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const only_clean_giraffe_flow = (params = {}, options = {}) => {
	return {
		en: en.only_clean_giraffe_flow,
		fr: fr.only_clean_giraffe_flow
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const maroon_novel_maggot_bake = (params = {}, options = {}) => {
	return {
		en: en.maroon_novel_maggot_bake,
		fr: fr.maroon_novel_maggot_bake
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const inclusive_ago_firefox_create = (params = {}, options = {}) => {
	return {
		en: en.inclusive_ago_firefox_create,
		fr: fr.inclusive_ago_firefox_create
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const every_spicy_bobcat_bake = (params = {}, options = {}) => {
	return {
		en: en.every_spicy_bobcat_bake,
		fr: fr.every_spicy_bobcat_bake
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fit_flaky_fish_blend = (params = {}, options = {}) => {
	return {
		en: en.fit_flaky_fish_blend,
		fr: fr.fit_flaky_fish_blend
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const busy_small_sawfish_hug = (params = {}, options = {}) => {
	return {
		en: en.busy_small_sawfish_hug,
		fr: fr.busy_small_sawfish_hug
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const topical_dark_jackal_conquer = (params = {}, options = {}) => {
	return {
		en: en.topical_dark_jackal_conquer,
		fr: fr.topical_dark_jackal_conquer
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const dry_tiny_platypus_feel = (params = {}, options = {}) => {
	return {
		en: en.dry_tiny_platypus_feel,
		fr: fr.dry_tiny_platypus_feel
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const raw_spicy_ibex_race = (params = {}, options = {}) => {
	return {
		en: en.raw_spicy_ibex_race,
		fr: fr.raw_spicy_ibex_race
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const slimy_quick_beetle_flip = (params = {}, options = {}) => {
	return {
		en: en.slimy_quick_beetle_flip,
		fr: fr.slimy_quick_beetle_flip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loved_sad_deer_grace = (params = {}, options = {}) => {
	return {
		en: en.loved_sad_deer_grace,
		fr: fr.loved_sad_deer_grace
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const chunky_strong_jannes_sew = (params = {}, options = {}) => {
	return {
		en: en.chunky_strong_jannes_sew,
		fr: fr.chunky_strong_jannes_sew
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const awake_suave_sawfish_spin = (params = {}, options = {}) => {
	return {
		en: en.awake_suave_sawfish_spin,
		fr: fr.awake_suave_sawfish_spin
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const white_that_frog_learn = (params = {}, options = {}) => {
	return {
		en: en.white_that_frog_learn,
		fr: fr.white_that_frog_learn
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const such_full_canary_build = (params = {}, options = {}) => {
	return {
		en: en.such_full_canary_build,
		fr: fr.such_full_canary_build
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_happy_mole_devour = (params = {}, options = {}) => {
	return {
		en: en.home_happy_mole_devour,
		fr: fr.home_happy_mole_devour
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const day_muddy_leopard_dance = (params = {}, options = {}) => {
	return {
		en: en.day_muddy_leopard_dance,
		fr: fr.day_muddy_leopard_dance
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const late_sharp_toad_heal = (params = {}, options = {}) => {
	return {
		en: en.late_sharp_toad_heal,
		fr: fr.late_sharp_toad_heal
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loud_civil_racoon_feel = (params = {}, options = {}) => {
	return {
		en: en.loud_civil_racoon_feel,
		fr: fr.loud_civil_racoon_feel
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nimble_super_impala_dart = (params = {}, options = {}) => {
	return {
		en: en.nimble_super_impala_dart,
		fr: fr.nimble_super_impala_dart
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const inner_equal_lemur_delight = (params = {}, options = {}) => {
	return {
		en: en.inner_equal_lemur_delight,
		fr: fr.inner_equal_lemur_delight
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const stout_agent_dachshund_buzz = (params = {}, options = {}) => {
	return {
		en: en.stout_agent_dachshund_buzz,
		fr: fr.stout_agent_dachshund_buzz
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const direct_funny_falcon_care = (params = {}, options = {}) => {
	return {
		en: en.direct_funny_falcon_care,
		fr: fr.direct_funny_falcon_care
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const such_mild_javelina_embrace = (params = {}, options = {}) => {
	return {
		en: en.such_mild_javelina_embrace,
		fr: fr.such_mild_javelina_embrace
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const few_frail_iguana_taste = (params = {}, options = {}) => {
	return {
		en: en.few_frail_iguana_taste,
		fr: fr.few_frail_iguana_taste
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const known_next_haddock_taste = (params = {}, options = {}) => {
	return {
		en: en.known_next_haddock_taste,
		fr: fr.known_next_haddock_taste
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cuddly_fuzzy_fly_spin = (params = {}, options = {}) => {
	return {
		en: en.cuddly_fuzzy_fly_spin,
		fr: fr.cuddly_fuzzy_fly_spin
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const east_new_trout_pinch = (params = {}, options = {}) => {
	return {
		en: en.east_new_trout_pinch,
		fr: fr.east_new_trout_pinch
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const white_proud_larva_blend = (params = {}, options = {}) => {
	return {
		en: en.white_proud_larva_blend,
		fr: fr.white_proud_larva_blend
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const teal_key_bulldog_animate = (params = {}, options = {}) => {
	return {
		en: en.teal_key_bulldog_animate,
		fr: fr.teal_key_bulldog_animate
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const suave_safe_larva_laugh = (params = {}, options = {}) => {
	return {
		en: en.suave_safe_larva_laugh,
		fr: fr.suave_safe_larva_laugh
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const teal_crazy_shrimp_foster = (params = {}, options = {}) => {
	return {
		en: en.teal_crazy_shrimp_foster,
		fr: fr.teal_crazy_shrimp_foster
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const safe_weary_duck_sail = (params = {}, options = {}) => {
	return {
		en: en.safe_weary_duck_sail,
		fr: fr.safe_weary_duck_sail
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const zippy_elegant_alpaca_mop = (params = {}, options = {}) => {
	return {
		en: en.zippy_elegant_alpaca_mop,
		fr: fr.zippy_elegant_alpaca_mop
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const busy_loose_bee_peel = (params = {}, options = {}) => {
	return {
		en: en.busy_loose_bee_peel,
		fr: fr.busy_loose_bee_peel
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bald_lower_nils_intend = (params = {}, options = {}) => {
	return {
		en: en.bald_lower_nils_intend,
		fr: fr.bald_lower_nils_intend
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lower_short_mammoth_renew = (params = {}, options = {}) => {
	return {
		en: en.lower_short_mammoth_renew,
		fr: fr.lower_short_mammoth_renew
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_crazy_toucan_chop = (params = {}, options = {}) => {
	return {
		en: en.quick_crazy_toucan_chop,
		fr: fr.quick_crazy_toucan_chop
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const heroic_dizzy_mayfly_dare = (params = {}, options = {}) => {
	return {
		en: en.heroic_dizzy_mayfly_dare,
		fr: fr.heroic_dizzy_mayfly_dare
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const dark_this_boar_agree = (params = {}, options = {}) => {
	return {
		en: en.dark_this_boar_agree,
		fr: fr.dark_this_boar_agree
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const free_mellow_tapir_quell = (params = {}, options = {}) => {
	return {
		en: en.free_mellow_tapir_quell,
		fr: fr.free_mellow_tapir_quell
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const active_helpful_grebe_pull = (params = {}, options = {}) => {
	return {
		en: en.active_helpful_grebe_pull,
		fr: fr.active_helpful_grebe_pull
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tangy_born_rat_clap = (params = {}, options = {}) => {
	return {
		en: en.tangy_born_rat_clap,
		fr: fr.tangy_born_rat_clap
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const wild_helpful_quail_wish = (params = {}, options = {}) => {
	return {
		en: en.wild_helpful_quail_wish,
		fr: fr.wild_helpful_quail_wish
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nimble_arable_stork_advise = (params = {}, options = {}) => {
	return {
		en: en.nimble_arable_stork_advise,
		fr: fr.nimble_arable_stork_advise
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const wild_arable_stork_advise = (params = {}, options = {}) => {
	return {
		en: en.wild_arable_stork_advise,
		fr: fr.wild_arable_stork_advise
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const gaudy_heavy_firefox_ascend = (params = {}, options = {}) => {
	return {
		en: en.gaudy_heavy_firefox_ascend,
		fr: fr.gaudy_heavy_firefox_ascend
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ companyName: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const basic_formal_hawk_spur = (params , options = {}) => {
	return {
		en: en.basic_formal_hawk_spur,
		fr: fr.basic_formal_hawk_spur
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ invitedByUsername: NonNullable<unknown>, companyName: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cozy_minor_pony_laugh = (params , options = {}) => {
	return {
		en: en.cozy_minor_pony_laugh,
		fr: fr.cozy_minor_pony_laugh
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loved_spare_toucan_fold = (params = {}, options = {}) => {
	return {
		en: en.loved_spare_toucan_fold,
		fr: fr.loved_spare_toucan_fold
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const dirty_aqua_grizzly_dine = (params = {}, options = {}) => {
	return {
		en: en.dirty_aqua_grizzly_dine,
		fr: fr.dirty_aqua_grizzly_dine
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const muddy_soft_jellyfish_gaze = (params = {}, options = {}) => {
	return {
		en: en.muddy_soft_jellyfish_gaze,
		fr: fr.muddy_soft_jellyfish_gaze
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const still_antsy_zebra_grace = (params = {}, options = {}) => {
	return {
		en: en.still_antsy_zebra_grace,
		fr: fr.still_antsy_zebra_grace
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const main_heroic_poodle_charm = (params = {}, options = {}) => {
	return {
		en: en.main_heroic_poodle_charm,
		fr: fr.main_heroic_poodle_charm
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const witty_calm_octopus_whisper = (params = {}, options = {}) => {
	return {
		en: en.witty_calm_octopus_whisper,
		fr: fr.witty_calm_octopus_whisper
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sleepy_brave_panda_dance = (params = {}, options = {}) => {
	return {
		en: en.sleepy_brave_panda_dance,
		fr: fr.sleepy_brave_panda_dance
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const curious_swift_gazelle_leap = (params = {}, options = {}) => {
	return {
		en: en.curious_swift_gazelle_leap,
		fr: fr.curious_swift_gazelle_leap
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const gentle_wise_owl_blink = (params = {}, options = {}) => {
	return {
		en: en.gentle_wise_owl_blink,
		fr: fr.gentle_wise_owl_blink
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lively_kind_dolphin_splash = (params = {}, options = {}) => {
	return {
		en: en.lively_kind_dolphin_splash,
		fr: fr.lively_kind_dolphin_splash
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const clever_quiet_fox_sneak = (params = {}, options = {}) => {
	return {
		en: en.clever_quiet_fox_sneak,
		fr: fr.clever_quiet_fox_sneak
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const happy_fluffy_bunny_hop = (params = {}, options = {}) => {
	return {
		en: en.happy_fluffy_bunny_hop,
		fr: fr.happy_fluffy_bunny_hop
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const brave_steady_turtle_crawl = (params = {}, options = {}) => {
	return {
		en: en.brave_steady_turtle_crawl,
		fr: fr.brave_steady_turtle_crawl
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const playful_agile_monkey_swing = (params = {}, options = {}) => {
	return {
		en: en.playful_agile_monkey_swing,
		fr: fr.playful_agile_monkey_swing
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const swift_graceful_deer_prance = (params = {}, options = {}) => {
	return {
		en: en.swift_graceful_deer_prance,
		fr: fr.swift_graceful_deer_prance
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const patient_wise_elephant_trumpet = (params = {}, options = {}) => {
	return {
		en: en.patient_wise_elephant_trumpet,
		fr: fr.patient_wise_elephant_trumpet
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loyal_strong_wolf_howl = (params = {}, options = {}) => {
	return {
		en: en.loyal_strong_wolf_howl,
		fr: fr.loyal_strong_wolf_howl
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const curious_nimble_squirrel_scurry = (params = {}, options = {}) => {
	return {
		en: en.curious_nimble_squirrel_scurry,
		fr: fr.curious_nimble_squirrel_scurry
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const elegant_calm_swan_glide = (params = {}, options = {}) => {
	return {
		en: en.elegant_calm_swan_glide,
		fr: fr.elegant_calm_swan_glide
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const friendly_playful_otter_swim = (params = {}, options = {}) => {
	return {
		en: en.friendly_playful_otter_swim,
		fr: fr.friendly_playful_otter_swim
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const majestic_wise_eagle_soar = (params = {}, options = {}) => {
	return {
		en: en.majestic_wise_eagle_soar,
		fr: fr.majestic_wise_eagle_soar
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const eloquent_gentle_butterfly_flutter = (params = {}, options = {}) => {
	return {
		en: en.eloquent_gentle_butterfly_flutter,
		fr: fr.eloquent_gentle_butterfly_flutter
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const brilliant_serene_hummingbird_hover = (params = {}, options = {}) => {
	return {
		en: en.brilliant_serene_hummingbird_hover,
		fr: fr.brilliant_serene_hummingbird_hover
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const clever_calm_penguin_design = (params = {}, options = {}) => {
	return {
		en: en.clever_calm_penguin_design,
		fr: fr.clever_calm_penguin_design
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const witty_brave_lion_create = (params = {}, options = {}) => {
	return {
		en: en.witty_brave_lion_create,
		fr: fr.witty_brave_lion_create
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const gentle_wise_owl_decorate = (params = {}, options = {}) => {
	return {
		en: en.gentle_wise_owl_decorate,
		fr: fr.gentle_wise_owl_decorate
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const eager_bright_flamingo_expect = (params = {}, options = {}) => {
	return {
		en: en.eager_bright_flamingo_expect,
		fr: fr.eager_bright_flamingo_expect
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const curious_happy_parrot_speak = (params = {}, options = {}) => {
	return {
		en: en.curious_happy_parrot_speak,
		fr: fr.curious_happy_parrot_speak
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const long_lazy_puffin_heal = (params = {}, options = {}) => {
	return {
		en: en.long_lazy_puffin_heal,
		fr: fr.long_lazy_puffin_heal
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ user: NonNullable<unknown>, product: NonNullable<unknown>, state: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const weird_alert_bumblebee_offer = (params , options = {}) => {
	return {
		en: en.weird_alert_bumblebee_offer,
		fr: fr.weird_alert_bumblebee_offer
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sleek_minor_leopard_honor = (params = {}, options = {}) => {
	return {
		en: en.sleek_minor_leopard_honor,
		fr: fr.sleek_minor_leopard_honor
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const main_whole_mammoth_arise = (params = {}, options = {}) => {
	return {
		en: en.main_whole_mammoth_arise,
		fr: fr.main_whole_mammoth_arise
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const male_formal_lamb_savor = (params = {}, options = {}) => {
	return {
		en: en.male_formal_lamb_savor,
		fr: fr.male_formal_lamb_savor
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const house_smart_ray_tear = (params = {}, options = {}) => {
	return {
		en: en.house_smart_ray_tear,
		fr: fr.house_smart_ray_tear
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sea_tangy_barbel_cure = (params = {}, options = {}) => {
	return {
		en: en.sea_tangy_barbel_cure,
		fr: fr.sea_tangy_barbel_cure
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tame_nice_baboon_pout = (params = {}, options = {}) => {
	return {
		en: en.tame_nice_baboon_pout,
		fr: fr.tame_nice_baboon_pout
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const swift_patchy_herring_cuddle = (params = {}, options = {}) => {
	return {
		en: en.swift_patchy_herring_cuddle,
		fr: fr.swift_patchy_herring_cuddle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const spicy_bland_llama_delight = (params = {}, options = {}) => {
	return {
		en: en.spicy_bland_llama_delight,
		fr: fr.spicy_bland_llama_delight
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const arable_awful_pig_sprout = (params = {}, options = {}) => {
	return {
		en: en.arable_awful_pig_sprout,
		fr: fr.arable_awful_pig_sprout
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const red_wide_bullock_evoke = (params , options = {}) => {
	return {
		en: en.red_wide_bullock_evoke,
		fr: fr.red_wide_bullock_evoke
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lofty_muddy_husky_roam = (params = {}, options = {}) => {
	return {
		en: en.lofty_muddy_husky_roam,
		fr: fr.lofty_muddy_husky_roam
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mellow_drab_panther_coax = (params = {}, options = {}) => {
	return {
		en: en.mellow_drab_panther_coax,
		fr: fr.mellow_drab_panther_coax
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const alive_tame_ocelot_pout = (params = {}, options = {}) => {
	return {
		en: en.alive_tame_ocelot_pout,
		fr: fr.alive_tame_ocelot_pout
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const game_grand_cat_edit = (params = {}, options = {}) => {
	return {
		en: en.game_grand_cat_edit,
		fr: fr.game_grand_cat_edit
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const civil_stale_shrimp_slurp = (params = {}, options = {}) => {
	return {
		en: en.civil_stale_shrimp_slurp,
		fr: fr.civil_stale_shrimp_slurp
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tasty_yummy_eagle_emerge = (params = {}, options = {}) => {
	return {
		en: en.tasty_yummy_eagle_emerge,
		fr: fr.tasty_yummy_eagle_emerge
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const gross_brave_mantis_thrive = (params = {}, options = {}) => {
	return {
		en: en.gross_brave_mantis_thrive,
		fr: fr.gross_brave_mantis_thrive
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const known_agent_tapir_climb = (params = {}, options = {}) => {
	return {
		en: en.known_agent_tapir_climb,
		fr: fr.known_agent_tapir_climb
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const frail_fuzzy_felix_arise = (params = {}, options = {}) => {
	return {
		en: en.frail_fuzzy_felix_arise,
		fr: fr.frail_fuzzy_felix_arise
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const great_gross_hedgehog_affirm = (params , options = {}) => {
	return {
		en: en.great_gross_hedgehog_affirm,
		fr: fr.great_gross_hedgehog_affirm
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const such_these_nuthatch_play = (params , options = {}) => {
	return {
		en: en.such_these_nuthatch_play,
		fr: fr.such_these_nuthatch_play
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const witty_early_leopard_forgive = (params , options = {}) => {
	return {
		en: en.witty_early_leopard_forgive,
		fr: fr.witty_early_leopard_forgive
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const any_actual_shrike_zap = (params , options = {}) => {
	return {
		en: en.any_actual_shrike_zap,
		fr: fr.any_actual_shrike_zap
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const big_patient_crocodile_devour = (params , options = {}) => {
	return {
		en: en.big_patient_crocodile_devour,
		fr: fr.big_patient_crocodile_devour
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const warm_sea_toad_relish = (params , options = {}) => {
	return {
		en: en.warm_sea_toad_relish,
		fr: fr.warm_sea_toad_relish
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const kind_heavy_frog_boil = (params , options = {}) => {
	return {
		en: en.kind_heavy_frog_boil,
		fr: fr.kind_heavy_frog_boil
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sharp_weak_opossum_zoom = (params = {}, options = {}) => {
	return {
		en: en.sharp_weak_opossum_zoom,
		fr: fr.sharp_weak_opossum_zoom
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const moving_flaky_felix_link = (params = {}, options = {}) => {
	return {
		en: en.moving_flaky_felix_link,
		fr: fr.moving_flaky_felix_link
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const aloof_busy_termite_nail = (params = {}, options = {}) => {
	return {
		en: en.aloof_busy_termite_nail,
		fr: fr.aloof_busy_termite_nail
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const aloof_still_snail_renew = (params = {}, options = {}) => {
	return {
		en: en.aloof_still_snail_renew,
		fr: fr.aloof_still_snail_renew
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const teary_whole_skunk_cherish = (params = {}, options = {}) => {
	return {
		en: en.teary_whole_skunk_cherish,
		fr: fr.teary_whole_skunk_cherish
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lucky_tasty_ant_work = (params = {}, options = {}) => {
	return {
		en: en.lucky_tasty_ant_work,
		fr: fr.lucky_tasty_ant_work
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ state: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const north_civil_mule_imagine = (params , options = {}) => {
	return {
		en: en.north_civil_mule_imagine,
		fr: fr.north_civil_mule_imagine
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStateProposed = (params = {}, options = {}) => {
	return {
		en: en.productStateProposed,
		fr: fr.productStateProposed
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStateLiked = (params = {}, options = {}) => {
	return {
		en: en.productStateLiked,
		fr: fr.productStateLiked
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStateDisliked = (params = {}, options = {}) => {
	return {
		en: en.productStateDisliked,
		fr: fr.productStateDisliked
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStateValidated = (params = {}, options = {}) => {
	return {
		en: en.productStateValidated,
		fr: fr.productStateValidated
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStateOrdered = (params = {}, options = {}) => {
	return {
		en: en.productStateOrdered,
		fr: fr.productStateOrdered
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStateShipped = (params = {}, options = {}) => {
	return {
		en: en.productStateShipped,
		fr: fr.productStateShipped
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStateReceived = (params = {}, options = {}) => {
	return {
		en: en.productStateReceived,
		fr: fr.productStateReceived
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStateInstalled = (params = {}, options = {}) => {
	return {
		en: en.productStateInstalled,
		fr: fr.productStateInstalled
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const dirty_dry_marlin_zip = (params = {}, options = {}) => {
	return {
		en: en.dirty_dry_marlin_zip,
		fr: fr.dirty_dry_marlin_zip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const jolly_actual_gorilla_revive = (params = {}, options = {}) => {
	return {
		en: en.jolly_actual_gorilla_revive,
		fr: fr.jolly_actual_gorilla_revive
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const flat_aware_yak_pat = (params = {}, options = {}) => {
	return {
		en: en.flat_aware_yak_pat,
		fr: fr.flat_aware_yak_pat
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lazy_fit_beetle_roam = (params = {}, options = {}) => {
	return {
		en: en.lazy_fit_beetle_roam,
		fr: fr.lazy_fit_beetle_roam
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const brief_light_pug_gaze = (params = {}, options = {}) => {
	return {
		en: en.brief_light_pug_gaze,
		fr: fr.brief_light_pug_gaze
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const candid_round_millipede_absorb = (params = {}, options = {}) => {
	return {
		en: en.candid_round_millipede_absorb,
		fr: fr.candid_round_millipede_absorb
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const these_true_platypus_enrich = (params = {}, options = {}) => {
	return {
		en: en.these_true_platypus_enrich,
		fr: fr.these_true_platypus_enrich
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bland_ideal_turkey_grin = (params = {}, options = {}) => {
	return {
		en: en.bland_ideal_turkey_grin,
		fr: fr.bland_ideal_turkey_grin
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const red_loved_liger_nurture = (params = {}, options = {}) => {
	return {
		en: en.red_loved_liger_nurture,
		fr: fr.red_loved_liger_nurture
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sunny_clear_moth_treat = (params = {}, options = {}) => {
	return {
		en: en.sunny_clear_moth_treat,
		fr: fr.sunny_clear_moth_treat
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const salty_tasty_baboon_offer = (params = {}, options = {}) => {
	return {
		en: en.salty_tasty_baboon_offer,
		fr: fr.salty_tasty_baboon_offer
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const short_ornate_raven_hope = (params = {}, options = {}) => {
	return {
		en: en.short_ornate_raven_hope,
		fr: fr.short_ornate_raven_hope
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const known_moving_loris_nail = (params = {}, options = {}) => {
	return {
		en: en.known_moving_loris_nail,
		fr: fr.known_moving_loris_nail
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const honest_good_crocodile_hush = (params = {}, options = {}) => {
	return {
		en: en.honest_good_crocodile_hush,
		fr: fr.honest_good_crocodile_hush
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mean_heroic_earthworm_catch = (params = {}, options = {}) => {
	return {
		en: en.mean_heroic_earthworm_catch,
		fr: fr.mean_heroic_earthworm_catch
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const unit_piece = (params = {}, options = {}) => {
	return {
		en: en.unit_piece,
		fr: fr.unit_piece
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const unit_kg = (params = {}, options = {}) => {
	return {
		en: en.unit_kg,
		fr: fr.unit_kg
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const unit_liter = (params = {}, options = {}) => {
	return {
		en: en.unit_liter,
		fr: fr.unit_liter
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const unit_meter = (params = {}, options = {}) => {
	return {
		en: en.unit_meter,
		fr: fr.unit_meter
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const unit_sqm = (params = {}, options = {}) => {
	return {
		en: en.unit_sqm,
		fr: fr.unit_sqm
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const unit_set = (params = {}, options = {}) => {
	return {
		en: en.unit_set,
		fr: fr.unit_set
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const helpful_pretty_lemur_zip = (params = {}, options = {}) => {
	return {
		en: en.helpful_pretty_lemur_zip,
		fr: fr.helpful_pretty_lemur_zip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const alert_day_owl_fond = (params = {}, options = {}) => {
	return {
		en: en.alert_day_owl_fond,
		fr: fr.alert_day_owl_fond
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lofty_whole_pug_twirl = (params = {}, options = {}) => {
	return {
		en: en.lofty_whole_pug_twirl,
		fr: fr.lofty_whole_pug_twirl
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pink_misty_cheetah_flop = (params = {}, options = {}) => {
	return {
		en: en.pink_misty_cheetah_flop,
		fr: fr.pink_misty_cheetah_flop
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nice_due_barbel_fear = (params = {}, options = {}) => {
	return {
		en: en.nice_due_barbel_fear,
		fr: fr.nice_due_barbel_fear
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const that_sharp_chipmunk_zoom = (params = {}, options = {}) => {
	return {
		en: en.that_sharp_chipmunk_zoom,
		fr: fr.that_sharp_chipmunk_zoom
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sharp_least_raven_catch = (params = {}, options = {}) => {
	return {
		en: en.sharp_least_raven_catch,
		fr: fr.sharp_least_raven_catch
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const solid_mad_quail_pray = (params = {}, options = {}) => {
	return {
		en: en.solid_mad_quail_pray,
		fr: fr.solid_mad_quail_pray
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const close_witty_penguin_support = (params = {}, options = {}) => {
	return {
		en: en.close_witty_penguin_support,
		fr: fr.close_witty_penguin_support
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const equal_royal_polecat_find = (params = {}, options = {}) => {
	return {
		en: en.equal_royal_polecat_find,
		fr: fr.equal_royal_polecat_find
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const green_weary_platypus_emerge = (params = {}, options = {}) => {
	return {
		en: en.green_weary_platypus_emerge,
		fr: fr.green_weary_platypus_emerge
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ count: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const main_misty_lionfish_slide = (params , options = {}) => {
	return {
		en: en.main_misty_lionfish_slide,
		fr: fr.main_misty_lionfish_slide
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const happy_factual_mare_breathe = (params = {}, options = {}) => {
	return {
		en: en.happy_factual_mare_breathe,
		fr: fr.happy_factual_mare_breathe
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sound_house_jaguar_devour = (params = {}, options = {}) => {
	return {
		en: en.sound_house_jaguar_devour,
		fr: fr.sound_house_jaguar_devour
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const real_good_clownfish_talk = (params = {}, options = {}) => {
	return {
		en: en.real_good_clownfish_talk,
		fr: fr.real_good_clownfish_talk
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bad_icy_mare_tap = (params = {}, options = {}) => {
	return {
		en: en.bad_icy_mare_tap,
		fr: fr.bad_icy_mare_tap
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const level_silly_gazelle_fulfill = (params = {}, options = {}) => {
	return {
		en: en.level_silly_gazelle_fulfill,
		fr: fr.level_silly_gazelle_fulfill
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pink_proof_canary_type = (params = {}, options = {}) => {
	return {
		en: en.pink_proof_canary_type,
		fr: fr.pink_proof_canary_type
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const watery_still_alpaca_aim = (params = {}, options = {}) => {
	return {
		en: en.watery_still_alpaca_aim,
		fr: fr.watery_still_alpaca_aim
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_mean_hornet_bake = (params = {}, options = {}) => {
	return {
		en: en.empty_mean_hornet_bake,
		fr: fr.empty_mean_hornet_bake
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nice_spare_wallaby_amuse = (params = {}, options = {}) => {
	return {
		en: en.nice_spare_wallaby_amuse,
		fr: fr.nice_spare_wallaby_amuse
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pretty_fine_sawfish_splash = (params = {}, options = {}) => {
	return {
		en: en.pretty_fine_sawfish_splash,
		fr: fr.pretty_fine_sawfish_splash
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ago_last_myna_intend = (params = {}, options = {}) => {
	return {
		en: en.ago_last_myna_intend,
		fr: fr.ago_last_myna_intend
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const helpful_mean_turtle_ask = (params = {}, options = {}) => {
	return {
		en: en.helpful_mean_turtle_ask,
		fr: fr.helpful_mean_turtle_ask
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const least_crisp_samuel_snip = (params = {}, options = {}) => {
	return {
		en: en.least_crisp_samuel_snip,
		fr: fr.least_crisp_samuel_snip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tough_jolly_butterfly_comfort = (params = {}, options = {}) => {
	return {
		en: en.tough_jolly_butterfly_comfort,
		fr: fr.tough_jolly_butterfly_comfort
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const topical_pretty_sloth_bump = (params = {}, options = {}) => {
	return {
		en: en.topical_pretty_sloth_bump,
		fr: fr.topical_pretty_sloth_bump
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const wide_aloof_ox_trim = (params = {}, options = {}) => {
	return {
		en: en.wide_aloof_ox_trim,
		fr: fr.wide_aloof_ox_trim
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const short_just_barbel_mix = (params = {}, options = {}) => {
	return {
		en: en.short_just_barbel_mix,
		fr: fr.short_just_barbel_mix
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bald_smug_fish_enrich = (params = {}, options = {}) => {
	return {
		en: en.bald_smug_fish_enrich,
		fr: fr.bald_smug_fish_enrich
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const petty_tough_eagle_quell = (params = {}, options = {}) => {
	return {
		en: en.petty_tough_eagle_quell,
		fr: fr.petty_tough_eagle_quell
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const salty_north_weasel_scoop = (params = {}, options = {}) => {
	return {
		en: en.salty_north_weasel_scoop,
		fr: fr.salty_north_weasel_scoop
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lofty_arable_rabbit_coax = (params = {}, options = {}) => {
	return {
		en: en.lofty_arable_rabbit_coax,
		fr: fr.lofty_arable_rabbit_coax
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const least_soft_bulldog_strive = (params = {}, options = {}) => {
	return {
		en: en.least_soft_bulldog_strive,
		fr: fr.least_soft_bulldog_strive
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const slow_steep_tiger_zip = (params = {}, options = {}) => {
	return {
		en: en.slow_steep_tiger_zip,
		fr: fr.slow_steep_tiger_zip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const last_neat_koala_prosper = (params = {}, options = {}) => {
	return {
		en: en.last_neat_koala_prosper,
		fr: fr.last_neat_koala_prosper
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const calm_wide_tern_pause = (params = {}, options = {}) => {
	return {
		en: en.calm_wide_tern_pause,
		fr: fr.calm_wide_tern_pause
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const wise_silly_jellyfish_loop = (params = {}, options = {}) => {
	return {
		en: en.wise_silly_jellyfish_loop,
		fr: fr.wise_silly_jellyfish_loop
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const white_dry_ladybug_arrive = (params = {}, options = {}) => {
	return {
		en: en.white_dry_ladybug_arrive,
		fr: fr.white_dry_ladybug_arrive
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const polite_noisy_vole_feast = (params = {}, options = {}) => {
	return {
		en: en.polite_noisy_vole_feast,
		fr: fr.polite_noisy_vole_feast
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bold_many_crow_fall = (params = {}, options = {}) => {
	return {
		en: en.bold_many_crow_fall,
		fr: fr.bold_many_crow_fall
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const civil_house_iguana_mend = (params = {}, options = {}) => {
	return {
		en: en.civil_house_iguana_mend,
		fr: fr.civil_house_iguana_mend
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const gray_witty_squid_zoom = (params = {}, options = {}) => {
	return {
		en: en.gray_witty_squid_zoom,
		fr: fr.gray_witty_squid_zoom
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const male_petty_ray_mop = (params = {}, options = {}) => {
	return {
		en: en.male_petty_ray_mop,
		fr: fr.male_petty_ray_mop
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sunny_direct_canary_savor = (params = {}, options = {}) => {
	return {
		en: en.sunny_direct_canary_savor,
		fr: fr.sunny_direct_canary_savor
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sound_brave_gecko_glow = (params = {}, options = {}) => {
	return {
		en: en.sound_brave_gecko_glow,
		fr: fr.sound_brave_gecko_glow
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const heavy_least_sheep_hunt = (params = {}, options = {}) => {
	return {
		en: en.heavy_least_sheep_hunt,
		fr: fr.heavy_least_sheep_hunt
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const blue_formal_trout_view = (params = {}, options = {}) => {
	return {
		en: en.blue_formal_trout_view,
		fr: fr.blue_formal_trout_view
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const simple_salty_beaver_bubble = (params = {}, options = {}) => {
	return {
		en: en.simple_salty_beaver_bubble,
		fr: fr.simple_salty_beaver_bubble
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const petty_warm_platypus_taste = (params = {}, options = {}) => {
	return {
		en: en.petty_warm_platypus_taste,
		fr: fr.petty_warm_platypus_taste
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const good_just_mare_conquer = (params = {}, options = {}) => {
	return {
		en: en.good_just_mare_conquer,
		fr: fr.good_just_mare_conquer
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ maxFilesCount: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const caring_drab_falcon_yell = (params , options = {}) => {
	return {
		en: en.caring_drab_falcon_yell,
		fr: fr.caring_drab_falcon_yell
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const broad_super_anaconda_scold = (params = {}, options = {}) => {
	return {
		en: en.broad_super_anaconda_scold,
		fr: fr.broad_super_anaconda_scold
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ invitedByUsername: NonNullable<unknown>, projectName: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const raw_only_goldfish_urge = (params , options = {}) => {
	return {
		en: en.raw_only_goldfish_urge,
		fr: fr.raw_only_goldfish_urge
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const east_bad_badger_trust = (params = {}, options = {}) => {
	return {
		en: en.east_bad_badger_trust,
		fr: fr.east_bad_badger_trust
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const late_dizzy_elephant_lock = (params = {}, options = {}) => {
	return {
		en: en.late_dizzy_elephant_lock,
		fr: fr.late_dizzy_elephant_lock
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const yummy_bland_badger_enchant = (params = {}, options = {}) => {
	return {
		en: en.yummy_bland_badger_enchant,
		fr: fr.yummy_bland_badger_enchant
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ projectName: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const super_elegant_grizzly_dig = (params , options = {}) => {
	return {
		en: en.super_elegant_grizzly_dig,
		fr: fr.super_elegant_grizzly_dig
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_cute_wolf_nudge = (params = {}, options = {}) => {
	return {
		en: en.home_cute_wolf_nudge,
		fr: fr.home_cute_wolf_nudge
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tiny_pretty_piranha_blink = (params = {}, options = {}) => {
	return {
		en: en.tiny_pretty_piranha_blink,
		fr: fr.tiny_pretty_piranha_blink
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const hour_hour_manatee_endure = (params = {}, options = {}) => {
	return {
		en: en.hour_hour_manatee_endure,
		fr: fr.hour_hour_manatee_endure
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const petty_zany_rook_lend = (params = {}, options = {}) => {
	return {
		en: en.petty_zany_rook_lend,
		fr: fr.petty_zany_rook_lend
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const only_noisy_koala_beam = (params = {}, options = {}) => {
	return {
		en: en.only_noisy_koala_beam,
		fr: fr.only_noisy_koala_beam
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fluffy_shy_oryx_rise = (params = {}, options = {}) => {
	return {
		en: en.fluffy_shy_oryx_rise,
		fr: fr.fluffy_shy_oryx_rise
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const grassy_round_bird_evoke = (params = {}, options = {}) => {
	return {
		en: en.grassy_round_bird_evoke,
		fr: fr.grassy_round_bird_evoke
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const brave_less_reindeer_hike = (params = {}, options = {}) => {
	return {
		en: en.brave_less_reindeer_hike,
		fr: fr.brave_less_reindeer_hike
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const calm_novel_jan_reap = (params = {}, options = {}) => {
	return {
		en: en.calm_novel_jan_reap,
		fr: fr.calm_novel_jan_reap
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const topical_great_puma_surge = (params = {}, options = {}) => {
	return {
		en: en.topical_great_puma_surge,
		fr: fr.topical_great_puma_surge
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lost_swift_okapi_cook = (params = {}, options = {}) => {
	return {
		en: en.lost_swift_okapi_cook,
		fr: fr.lost_swift_okapi_cook
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const grassy_large_warthog_lift = (params = {}, options = {}) => {
	return {
		en: en.grassy_large_warthog_lift,
		fr: fr.grassy_large_warthog_lift
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const proud_top_scallop_find = (params = {}, options = {}) => {
	return {
		en: en.proud_top_scallop_find,
		fr: fr.proud_top_scallop_find
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fresh_tangy_wren_jump = (params = {}, options = {}) => {
	return {
		en: en.fresh_tangy_wren_jump,
		fr: fr.fresh_tangy_wren_jump
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const trite_safe_toad_pave = (params = {}, options = {}) => {
	return {
		en: en.trite_safe_toad_pave,
		fr: fr.trite_safe_toad_pave
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const less_legal_termite_nudge = (params , options = {}) => {
	return {
		en: en.less_legal_termite_nudge,
		fr: fr.less_legal_termite_nudge
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const muddy_mushy_giraffe_clasp = (params = {}, options = {}) => {
	return {
		en: en.muddy_mushy_giraffe_clasp,
		fr: fr.muddy_mushy_giraffe_clasp
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const patient_solid_marmot_assure = (params = {}, options = {}) => {
	return {
		en: en.patient_solid_marmot_assure,
		fr: fr.patient_solid_marmot_assure
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const low_acidic_alpaca_spur = (params = {}, options = {}) => {
	return {
		en: en.low_acidic_alpaca_spur,
		fr: fr.low_acidic_alpaca_spur
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const alive_glad_marlin_type = (params = {}, options = {}) => {
	return {
		en: en.alive_glad_marlin_type,
		fr: fr.alive_glad_marlin_type
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fine_blue_poodle_bloom = (params = {}, options = {}) => {
	return {
		en: en.fine_blue_poodle_bloom,
		fr: fr.fine_blue_poodle_bloom
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const wacky_tidy_seahorse_jump = (params = {}, options = {}) => {
	return {
		en: en.wacky_tidy_seahorse_jump,
		fr: fr.wacky_tidy_seahorse_jump
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const inclusive_knotty_jackal_mop = (params = {}, options = {}) => {
	return {
		en: en.inclusive_knotty_jackal_mop,
		fr: fr.inclusive_knotty_jackal_mop
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pink_few_tiger_spur = (params = {}, options = {}) => {
	return {
		en: en.pink_few_tiger_spur,
		fr: fr.pink_few_tiger_spur
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bold_odd_mole_seek = (params = {}, options = {}) => {
	return {
		en: en.bold_odd_mole_seek,
		fr: fr.bold_odd_mole_seek
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const soft_kind_lynx_love = (params = {}, options = {}) => {
	return {
		en: en.soft_kind_lynx_love,
		fr: fr.soft_kind_lynx_love
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const raw_that_mallard_grow = (params = {}, options = {}) => {
	return {
		en: en.raw_that_mallard_grow,
		fr: fr.raw_that_mallard_grow
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sea_petty_turtle_honor = (params = {}, options = {}) => {
	return {
		en: en.sea_petty_turtle_honor,
		fr: fr.sea_petty_turtle_honor
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const deft_civil_hamster_offer = (params = {}, options = {}) => {
	return {
		en: en.deft_civil_hamster_offer,
		fr: fr.deft_civil_hamster_offer
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const active_spicy_spider_relish = (params = {}, options = {}) => {
	return {
		en: en.active_spicy_spider_relish,
		fr: fr.active_spicy_spider_relish
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bright_tired_yak_exhale = (params = {}, options = {}) => {
	return {
		en: en.bright_tired_yak_exhale,
		fr: fr.bright_tired_yak_exhale
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const main_only_squirrel_earn = (params = {}, options = {}) => {
	return {
		en: en.main_only_squirrel_earn,
		fr: fr.main_only_squirrel_earn
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ago_that_lobster_hint = (params = {}, options = {}) => {
	return {
		en: en.ago_that_lobster_hint,
		fr: fr.ago_that_lobster_hint
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lime_lofty_firefox_clip = (params = {}, options = {}) => {
	return {
		en: en.lime_lofty_firefox_clip,
		fr: fr.lime_lofty_firefox_clip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bad_sweet_fly_grin = (params = {}, options = {}) => {
	return {
		en: en.bad_sweet_fly_grin,
		fr: fr.bad_sweet_fly_grin
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const last_lazy_felix_dash = (params = {}, options = {}) => {
	return {
		en: en.last_lazy_felix_dash,
		fr: fr.last_lazy_felix_dash
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lazy_dull_scallop_build = (params = {}, options = {}) => {
	return {
		en: en.lazy_dull_scallop_build,
		fr: fr.lazy_dull_scallop_build
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const good_green_reindeer_vent = (params = {}, options = {}) => {
	return {
		en: en.good_green_reindeer_vent,
		fr: fr.good_green_reindeer_vent
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const dark_mealy_loris_pray = (params = {}, options = {}) => {
	return {
		en: en.dark_mealy_loris_pray,
		fr: fr.dark_mealy_loris_pray
	}[options.languageTag ?? languageTag()]()
}


