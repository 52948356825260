
/* eslint-disable */
/** 
* This file contains language specific message functions for tree-shaking. 
* 
*! WARNING: Only import messages from this file if you want to manually
*! optimize your bundle. Else, import from the `messages.js` file. 
* 
* Your bundler will (in the future) automatically replace the index function 
* with a language specific message function in the build step. 
*/


	
/**
 * @param {{ projectTitle: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activitiesNewMessageSubitle = (params) => `Dans ${params.projectTitle}`




	
/**
 * @param {{ projectTitle: NonNullable<unknown>, productTitle: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activitiesNewMessageSubitleWithProduct = (params) => `Dans <strong>${params.projectTitle}</strong> sur <strong>${params.productTitle}</strong>`




	
/**
 * @param {{ userName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activitiesNewMessageTitle = (params) => `${params.userName} a laissé un message`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activitiesNoActivities = () => `Aucune activité`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activitiesNoActivitiesDescription = () => `Lorsque quelqu'un laissera un message, celui-ci apparaitra ici.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activitiesNoActivitiesTitle = () => `Vous êtes à jour !`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const areas = () => `Zones`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const chatNotFound = () => `Chat introuvable`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const companySettings = () => `Paramètres`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasAttic = () => `Grenier`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasBasement = () => `Sous-sol`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasBathroom = () => `Salle de bain`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasBedroom = () => `Chambre`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasCraftRoom = () => `Atelier`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasDiningRoom = () => `Salle à manger`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasFoyer = () => `Entrée`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasGameRoom = () => `Salle de jeux`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasGarage = () => `Garage`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasGarden = () => `Jardin`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasGuestRoom = () => `Chambre d'amis`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasGym = () => `Salle de sport`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasHomeOffice = () => `Bureau`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasHomeTheater = () => `Home cinéma`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasKitchen = () => `Cuisine`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasLaundryRoom = () => `Buanderie`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasLibrary = () => `Bibliothèque`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasLivingRoom = () => `Salon`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasMasterBedroom = () => `Chambre principale`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasMudroom = () => `Vestibule`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasPantry = () => `Garde-manger`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasPatio = () => `Patio`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasSunroom = () => `Véranda`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasSwimingPool = () => `Piscine`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasTerrace = () => `Terrasse`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasWineCellar = () => `Cave à vin`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const homepageTagline = () => `Le gestionnaire de projet à destination des architects et designers d'intérieur`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const logout = () => `Se déconnecter`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const newProject = () => `Nouveau projet`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const members = () => `Membres`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productNotFound = () => `Produit introuvable`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsBatchUpdate = () => `Mettre à jour`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsBatchUpdateModalClose = () => `Annuler`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsBatchUpdateModalCta = () => `Sauvegarder`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsBatchUpdateModalTitle = () => `Mise à jour des produits`




	
/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsBatchUpdateModalWarning = (params) => `Vous êtes sur le point de mettre à jour ${params.count} produits.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsCount = () => `Produits`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsEditArea = () => `Zone`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsEditState = () => `Statut`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsEditTypes = () => `Types`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsEmptyClearFilters = () => `de réinitialiser les filtres.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsEmptyImageAlt = () => `Aucun résultat`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsEmptySubtitle = () => `Merci de modifier votre recherche ou`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsEmptyTitle = () => `Aucun résultats trouvés pour votre recherche`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsExport = () => `Exporter`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsExportInvoice = () => `Facture`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsExportPurchaseOrder = () => `Bon de commande`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsFiltersClearAll = () => `Réinitialiser`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsSearchByTitle = () => `Rechercher par titre`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsSelectAll = () => `Tout séléctionner`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStatesDisliked = () => `Pas aimé`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStatesInstalled = () => `Installé`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStatesLiked = () => `Aimé`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStatesOrdered = () => `Commandé`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStatesProposed = () => `Proposé`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStatesReceived = () => `Reçu`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStatesShipped = () => `En cours de livraison`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStatesUnspecified = () => `Non spécifié`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStatesValidated = () => `Validé`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productTypesEmpty = () => `Créez un nouveau type en saisissant du texte dans le champ et en appuyant sur Entrée.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productTypesPlaceholder = () => `Selectionner ou créer un type`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const projectNavChat = () => `Chat`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const projectNavProducts = () => `Produits`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const projectNavSettings = () => `Configuration`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const projectNewDescriptionLabel = () => `Description`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const projectNewSubmit = () => `Créer`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const projectNewTitleLabel = () => `Titre`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const projectEditSubmit = () => `Mettre à jour`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const projectNotFound = () => `Projet introuvable`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const projects = () => `Projets`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signIn = () => `Se connecter`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signInTitle = () => `Connectez vous à votre compte`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signInEmail = () => `Email`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signInPassword = () => `Mot de passse`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signInForgotPassword = () => `Mot de passse oublié ?`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signUp = () => `S'inscrire`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signUpFirstName = () => `Prénom`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signUpLastName = () => `Nom`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signUpEmail = () => `Email`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signUpPassword = () => `Mot de passe`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signUpPasswordConfirmation = () => `Confirmation de mot passe`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sortedBy = () => `Tri par`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sortedByArea = () => `Zone`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sortedByCreatedAt = () => `Date de création`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sortedByState = () => `Statut`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const states = () => `Statuts`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const types = () => `Types`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const userSettings = () => `Compte`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const large_polite_gibbon_relish = () => `Doit être un e-mail valide`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const dull_that_mole_grip = () => `Le mot de passe doit être au moins de 6 caractères`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const wacky_bold_fireant_mop = () => `La confirmation du mot de passe est requise`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const big_known_anteater_snap = () => `La confirmation du mot de passe ne correspond pas`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lost_away_finch_dine = () => `Le prénom est requis`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const calm_strong_crow_read = () => `Le nom de famille est requis`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const petty_bright_dog_compose = () => `L'e-mail est requis`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const chunky_light_skunk_hike = () => `Nom d'utilisateur ou mot de passe invalide`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const topical_frail_polecat_dial = () => `Succès!`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tasty_drab_lion_beam = () => `Titre`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const left_vexed_ocelot_dial = () => `Sauvegarder`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fresh_icy_nuthatch_cuddle = () => `Vous n'avez pas encore d'entreprises.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fun_upper_cobra_type = () => `Nouvelle compagnie`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const free_this_dragonfly_gulp = () => `Êtes-vous sûr de vouloir transférer le propriétaire ?\\nVous serez rétrogradé au rang d'administrateur.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const basic_still_lionfish_pull = () => `Inviter un nouveau membre`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const curly_drab_niklas_fold = () => `Invitations en attente`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const such_muddy_tapir_hike = () => `Membres`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const zany_few_bee_ask = () => `Une invitation a déjà été envoyée à cet email.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cozy_topical_finch_urge = () => `Cet utilisateur est déjà membre de l'entreprise.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_plane_koala_quell = () => `Nouvelle invitation à une entreprise!`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const merry_brief_dachshund_pinch = () => `Bonjour le monde`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const extra_male_anaconda_treat = () => `E-mail`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const vexed_noisy_vulture_hurl = () => `Envoyer une invitation`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const direct_ago_thrush_believe = () => `Annuler`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bold_home_iguana_revive = () => `Nouveau projet`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const deft_sad_loris_roar = () => `Générer une facture`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tiny_smart_stork_splash = () => `Numéro de facture`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const due_lazy_pug_heart = () => `Bon de commande #`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const large_bald_duck_spin = () => `Date`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loud_main_poodle_dig = () => `Date d'échéance`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const keen_ago_cockroach_aid = () => `Note (haut de page)`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const hour_legal_firefox_zip = () => `Aperçu`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sad_clear_otter_feast = () => `Fermer`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const crazy_bald_bear_ask = () => `Facture n°`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const antsy_helpful_mayfly_treasure = () => `Description`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const large_steep_jaguar_push = () => `Quantité`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bald_steep_dingo_belong = () => `TVA`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const low_sour_tuna_ripple = () => `Prix HT`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tangy_quaint_shrike_dare = () => `Sous-total`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const glad_sharp_cobra_flop = () => `TVA`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const slimy_tasty_goose_care = () => `Expédition`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pink_stale_frog_race = () => `Remise`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const close_good_lobster_achieve = () => `Total à payer`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const north_main_ox_ripple = () => `Créer`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const equal_home_hyena_harbor = () => `Annuler`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const jolly_still_sloth_smile = () => `Modifier`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const major_extra_camel_dare = () => `Commentaires`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const away_shy_jaguar_jolt = () => `Sauvegarder`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const that_flaky_pelican_cheer = () => `Une erreur inattendue est apparue`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const patient_next_hyena_win = () => `Une erreur inattendue est apparue`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const new_true_rooster_attend = () => `Modifier`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const soft_brave_herring_splash = () => `Client`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const alive_zippy_kestrel_flow = () => `Membres`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const raw_tiny_husky_fry = () => `Archiver`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const polite_wild_mole_slide = () => `Archiver`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const topical_loved_kangaroo_offer = () => `Inviter un client`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const such_trite_rat_slurp = () => `Invitations en attente`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const clean_teal_toucan_zap = () => `Clients`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const clean_solid_polecat_grace = () => `Nom du client`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const only_clean_giraffe_flow = () => `Adresse de facturation`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const maroon_novel_maggot_bake = () => `L'adresse de livraison est la même que l'adresse de facturation.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const inclusive_ago_firefox_create = () => `Adresse de livraison`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const every_spicy_bobcat_bake = () => `Une invitation a déjà été envoyée à cet email.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fit_flaky_fish_blend = () => `Cet utilisateur est déjà client du projet.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const busy_small_sawfish_hug = () => `Nouvelle invitation à un projet !`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const topical_dark_jackal_conquer = () => `Bonjour le monde`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const dry_tiny_platypus_feel = () => `E-mail`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const raw_spicy_ibex_race = () => `Inviter un nouveau client`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const slimy_quick_beetle_flip = () => `Envoyer une invitation`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loved_sad_deer_grace = () => `Annuler`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const chunky_strong_jannes_sew = () => `Êtes-vous sûr de vouloir transférer le propriétaire ?\\nVous serez rétrogradé au rang d'administrateur.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const awake_suave_sawfish_spin = () => `Inviter un nouveaux membre`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const white_that_frog_learn = () => `Rôle`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const such_full_canary_build = () => `Si l'utilisateur ne figure pas dans la liste ci-dessus, veuillez d'abord l'ajouter aux membres de l'entreprise.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_happy_mole_devour = () => `Accédez à la liste des membres de l'entreprise`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const day_muddy_leopard_dance = () => `Ajouter un membre`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const late_sharp_toad_heal = () => `Annuler`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loud_civil_racoon_feel = () => `On dirait que c'est calme ici...
<br />
🌟 Soyez l'étincelle ! Lancez la conversation maintenant !`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nimble_super_impala_dart = () => `Envoyer`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const inner_equal_lemur_delight = () => `Tapez un message...`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const stout_agent_dachshund_buzz = () => `Inviter un nouveau membre`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const direct_funny_falcon_care = () => `Inviter un nouveau membre`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const such_mild_javelina_embrace = () => `Membres`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const few_frail_iguana_taste = () => `Il n'y a pas encore de produits pour ce projet.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const known_next_haddock_taste = () => `Créons-en un !`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cuddly_fuzzy_fly_spin = () => `Mot de passe oublié`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const east_new_trout_pinch = () => `Entrez l'adresse e-mail associée à votre compte et nous vous enverrons
          un lien pour réinitialiser votre mot de passe.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const white_proud_larva_blend = () => `Envoyer les instructions`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const teal_key_bulldog_animate = () => `Retour à la page de connexion`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const suave_safe_larva_laugh = () => `E-mail`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const teal_crazy_shrimp_foster = () => `Votre mot de passe a été mis à jour !`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const safe_weary_duck_sail = () => `Vous pouvez maintenant vous connecter avec votre nouveau mot de passe`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const zippy_elegant_alpaca_mop = () => `Aller à la page de connexion`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const busy_loose_bee_peel = () => `Choisissez un nouveau mot de passe`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bald_lower_nils_intend = () => `Mot de passe`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lower_short_mammoth_renew = () => `Confirmation du mot de passe`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_crazy_toucan_chop = () => `Réinitialiser le mot de passe`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const heroic_dizzy_mayfly_dare = () => `Retour à la page de connexion`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const dark_this_boar_agree = () => `Projets`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const free_mellow_tapir_quell = () => `Rôle`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const active_helpful_grebe_pull = () => `vous a invité à rejoindre`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tangy_born_rat_clap = () => `Souhaitez-vous accepter ?`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const wild_helpful_quail_wish = () => `Accepter`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nimble_arable_stork_advise = () => `Décliner`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const wild_arable_stork_advise = () => `Invitation à une entreprise`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const gaudy_heavy_firefox_ascend = () => `Invitation à un projet`




	
/**
 * @param {{ companyName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const basic_formal_hawk_spur = (params) => `Rejoignez <strong>${params.companyName}</strong>`




	
/**
 * @param {{ invitedByUsername: NonNullable<unknown>, companyName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cozy_minor_pony_laugh = (params) => `<strong>${params.invitedByUsername}</strong> vous a invité à rejoindre <strong>${params.companyName}</strong> .`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loved_spare_toucan_fold = () => `Rejoignez l'entreprise`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const dirty_aqua_grizzly_dine = () => `ou copiez et collez cette URL dans votre navigateur :`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const muddy_soft_jellyfish_gaze = () => `<strong>Remarque :</strong> ce lien expirera dans 48 heures.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const still_antsy_zebra_grace = () => `Bonjour,`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const main_heroic_poodle_charm = () => `Projets`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const witty_calm_octopus_whisper = () => `Simplifiez la collaboration avec vos clients`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sleepy_brave_panda_dance = () => `Archispec est l'outil indispensable pour fluidifier et optimiser vos échanges avec vos clients tout au long de vos projets.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const curious_swift_gazelle_leap = () => `S'inscrire`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const gentle_wise_owl_blink = () => `Chinez les produits parfaits`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lively_kind_dolphin_splash = () => `Explorez le web ou consultez votre propre base de données pour trouver les produits les plus adaptés à vos projets.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const clever_quiet_fox_sneak = () => `Présentez les produits à vos clients`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const happy_fluffy_bunny_hop = () => `Partagez facilement vos sélections et propositions de produits avec vos clients via une interface intuitive.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const brave_steady_turtle_crawl = () => `Recueillez des retours instantanés`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const playful_agile_monkey_swing = () => `Vos clients peuvent commenter, approuver ou refuser les produits en quelques clics, facilitant ainsi la prise de décision.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const swift_graceful_deer_prance = () => `Générez des bons de commande en un clic`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const patient_wise_elephant_trumpet = () => `Automatisez la création de bons de commande pour les produits validés, directement destinés à vos fournisseurs.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loyal_strong_wolf_howl = () => `Suivez l'avancement en temps réel`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const curious_nimble_squirrel_scurry = () => `Tenez vos clients informés de l'évolution de chaque produit, de la commande à la livraison.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const elegant_calm_swan_glide = () => `Simplifiez la gestion des factures`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const friendly_playful_otter_swim = () => `Envoyez les factures à vos clients rapidement et sans effort.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const majestic_wise_eagle_soar = () => `Archispec, c'est l'outil indispensable pour une gestion simplifiée, une communication fluide et des projets menés à bien avec efficacité.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const eloquent_gentle_butterfly_flutter = () => `Grâce à Archispec, nous avons considérablement réduit le temps passé sur la gestion administrative, nous permettant de nous concentrer davantage sur la créativité et la satisfaction client.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const brilliant_serene_hummingbird_hover = () => `La facilité d'utilisation d'Archispec combinée à sa puissance fonctionnelle en fait un atout majeur pour notre entreprise. Nos projets n’ont jamais été aussi bien organisés.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const clever_calm_penguin_design = () => `Architecte d'intérieur`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const witty_brave_lion_create = () => `Produit de conception`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const gentle_wise_owl_decorate = () => `Décoratrice`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const eager_bright_flamingo_expect = () => `Notre proposition de valeur`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const curious_happy_parrot_speak = () => `Que disent nos clients`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const long_lazy_puffin_heal = () => `Mise à jour du statut du produit`




	
/**
 * @param {{ user: NonNullable<unknown>, product: NonNullable<unknown>, state: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const weird_alert_bumblebee_offer = (params) => `${params.user} a défini ${params.product} sur ${params.state} .`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sleek_minor_leopard_honor = () => `Tout effacer`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const main_whole_mammoth_arise = () => `Saisissez un titre concis et descriptif pour votre produit.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const male_formal_lamb_savor = () => `Fournissez une description détaillée de votre produit, y compris ses caractéristiques et ses avantages.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const house_smart_ray_tear = () => `Téléchargez jusqu'à 5 images présentant votre produit. Celles-ci seront affichées dans la galerie de produits.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sea_tangy_barbel_cure = () => `Sélectionnez la zone à laquelle appartient ce produit dans votre projet.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tame_nice_baboon_pout = () => `Choisissez l'état ou le statut actuel de votre produit.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const swift_patchy_herring_cuddle = () => `Ajoutez des balises pertinentes pour aider à catégoriser et à rechercher votre produit.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const spicy_bland_llama_delight = () => `Vous avez déjà un compte ?`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const arable_awful_pig_sprout = () => `Vous n'avez pas encore de compte ?`




	
/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const red_wide_bullock_evoke = (params) => `Aimé il y a ${params.date}.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lofty_muddy_husky_roam = () => `Valider`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mellow_drab_panther_coax = () => `Vous avez validé ce produit. Vous serez prévenu dès qu'il sera commandé.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const alive_tame_ocelot_pout = () => `Vous pouvez maintenant le valider. Ce qui nous permettra de passer la commande au plus vite.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const game_grand_cat_edit = () => `Dites-nous si vous l'aimez ou non.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const civil_stale_shrimp_slurp = () => `Cet object sera ajouté dans votre devis dans les plus bref délais.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tasty_yummy_eagle_emerge = () => `Nous vous informerons dès son expédition.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const gross_brave_mantis_thrive = () => `Nous vous informerons lorsqu'il sera reçu.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const known_agent_tapir_climb = () => `Le produit a été installé.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const frail_fuzzy_felix_arise = () => `Nous trouverons autre chose qui vous conviendra.`




	
/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const great_gross_hedgehog_affirm = (params) => `Proposé il y a ${params.date}`




	
/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const such_these_nuthatch_play = (params) => `Pas apprécié il y a ${params.date}`




	
/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const witty_early_leopard_forgive = (params) => `Validé il y a ${params.date}`




	
/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const any_actual_shrike_zap = (params) => `Commandé il y a ${params.date}`




	
/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const big_patient_crocodile_devour = (params) => `Expédié il y a ${params.date}`




	
/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const warm_sea_toad_relish = (params) => `Reçu il y a ${params.date}`




	
/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const kind_heavy_frog_boil = (params) => `Installé il y a ${params.date}`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sharp_weak_opossum_zoom = () => `Le produit a été reçu. Nous vous informerons lorsqu'il sera installé.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const moving_flaky_felix_link = () => `Changer d'avis`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const aloof_busy_termite_nail = () => `Confirmer la validation`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const aloof_still_snail_renew = () => `Etes-vous sûr de vouloir valider ce produit ? Cette action ne peut pas être annulée.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const teary_whole_skunk_cherish = () => `Annuler`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lucky_tasty_ant_work = () => `Confirmer`




	
/**
 * @param {{ state: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const north_civil_mule_imagine = (params) => `statut`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStateProposed = () => `Proposé`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStateLiked = () => `Aimé`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStateDisliked = () => `Pas apprécié`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStateValidated = () => `Validé`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStateOrdered = () => `Commandé`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStateShipped = () => `Expédié`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStateReceived = () => `Reçu`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStateInstalled = () => `Installé`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const dirty_dry_marlin_zip = () => `Vous avez changé d'avis`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const jolly_actual_gorilla_revive = () => `Vos produits arrivent bientôt !`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const flat_aware_yak_pat = () => `Nous mettons tout en œuvre pour vous proposer des produits de qualité, parfaitement adaptés à vos besoins.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lazy_fit_beetle_roam = () => `Vous serez averti lorsque nous ajouterons quelque chose !`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const brief_light_pug_gaze = () => `En attendant, pourquoi ne pas nous laisser un message ? Nous serions ravis d'avoir de vos nouvelles !`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const candid_round_millipede_absorb = () => `Démarrer une conversation`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const these_true_platypus_enrich = () => `Commentaires`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bland_ideal_turkey_grin = () => `Infos`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const red_loved_liger_nurture = () => `Majoration`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sunny_clear_moth_treat = () => `TVA (%)`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const salty_tasty_baboon_offer = () => `Frais de port`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const short_ornate_raven_hope = () => `Remise`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const known_moving_loris_nail = () => `Devise`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const honest_good_crocodile_hush = () => `Prix public`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mean_heroic_earthworm_catch = () => `Prix total`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const unit_piece = () => `Pièce`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const unit_kg = () => `Kilogramme`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const unit_liter = () => `Litre`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const unit_meter = () => `Mètre`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const unit_sqm = () => `Mètre carré`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const unit_set = () => `Lot`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const helpful_pretty_lemur_zip = () => `Fournisseur`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const alert_day_owl_fond = () => `Fournisseurs`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lofty_whole_pug_twirl = () => `fabricants`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pink_misty_cheetah_flop = () => `Fabricant`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nice_due_barbel_fear = () => `Importer un produit`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const that_sharp_chipmunk_zoom = () => `Pour importer rapidement un produit, saisissez l'URL de la page produit depuis n'importe quel site. Notre IA tentera d'extraire les détails du produit pour vous.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sharp_least_raven_catch = () => `Fait de la magie d'IA...`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const solid_mad_quail_pray = () => `Remplir automatiquement`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const close_witty_penguin_support = () => `Entrez l'URL du produit`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const equal_royal_polecat_find = () => `Saisissez un titre concis et descriptif pour votre produit.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const green_weary_platypus_emerge = () => `Fournissez une description détaillée de votre produit, y compris ses caractéristiques et ses avantages.`




	
/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const main_misty_lionfish_slide = (params) => `Téléchargez jusqu'à ${params.count} images présentant votre produit. Celles-ci seront affichées dans la galerie de produits.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const happy_factual_mare_breathe = () => `Sélectionnez la zone ou la catégorie à laquelle appartient ce produit dans votre projet.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sound_house_jaguar_devour = () => `Sélectionnez le fournisseur pour ce produit.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const real_good_clownfish_talk = () => `Sélectionnez le fabricant pour ce produit.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bad_icy_mare_tap = () => `Choisissez l'état ou le statut actuel de votre produit dans le processus de développement.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const level_silly_gazelle_fulfill = () => `Ajoutez des balises pertinentes pour aider à catégoriser et à rechercher votre produit.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pink_proof_canary_type = () => `Saisissez la quantité et sélectionnez l'unité de mesure du produit.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const watery_still_alpaca_aim = () => `Saisissez les détails de tarification du produit.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_mean_hornet_bake = () => `Titre`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nice_spare_wallaby_amuse = () => `Description`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pretty_fine_sawfish_splash = () => `Statut`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ago_last_myna_intend = () => `Quantité`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const helpful_mean_turtle_ask = () => `Unité`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const least_crisp_samuel_snip = () => `Ville`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tough_jolly_butterfly_comfort = () => `Code postal`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const topical_pretty_sloth_bump = () => `Région`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const wide_aloof_ox_trim = () => `Pays`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const short_just_barbel_mix = () => `Rue`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bald_smug_fish_enrich = () => `Saisissez une ou plusieurs URL d'image, chacune sur une nouvelle ligne`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const petty_tough_eagle_quell = () => `URL des images`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const salty_north_weasel_scoop = () => `Vous avez atteint le nombre maximum de fichiers que vous pouvez télécharger.
N'hésitez pas à supprimer l'un des fichiers existants pour faire de la place à un
nouveau.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lofty_arable_rabbit_coax = () => `Choisissez un fichier`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const least_soft_bulldog_strive = () => `Téléchargement échoué`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const slow_steep_tiger_zip = () => `Une erreur s'est produite lors du téléchargement de votre fichier. Veuillez réessayer.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const last_neat_koala_prosper = () => `La récupération de l'image a échoué`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const calm_wide_tern_pause = () => `Nous n'avons pas pu récupérer l'image à partir de l'URL fournie. Veuillez vérifier que l'URL est correcte et réessayer.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const wise_silly_jellyfish_loop = () => `Utilisateur`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const white_dry_ladybug_arrive = () => `Sélectionner un rôle`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const polite_noisy_vole_feast = () => `Nom du client`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bold_many_crow_fall = () => `Client`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const civil_house_iguana_mend = () => `date`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const gray_witty_squid_zoom = () => `Service après vente : garantie 2 ans, etc.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const male_petty_ray_mop = () => `date`



export { sunny_direct_canary_savor } from "./en.js"

export { sound_brave_gecko_glow } from "./en.js"

export { heavy_least_sheep_hunt } from "./en.js"

export { blue_formal_trout_view } from "./en.js"

export { simple_salty_beaver_bubble } from "./en.js"

export { petty_warm_platypus_taste } from "./en.js"

export { good_just_mare_conquer } from "./en.js"

export { caring_drab_falcon_yell } from "./en.js"

export { broad_super_anaconda_scold } from "./en.js"


	
/**
 * @param {{ invitedByUsername: NonNullable<unknown>, projectName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const raw_only_goldfish_urge = (params) => `<strong>${params.invitedByUsername}</strong> vous à invité à rejoindre <strong>${params.projectName}</strong>.`



export { east_bad_badger_trust } from "./en.js"

export { late_dizzy_elephant_lock } from "./en.js"

export { yummy_bland_badger_enchant } from "./en.js"


	
/**
 * @param {{ projectName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const super_elegant_grizzly_dig = (params) => `Rejoignez <strong>${params.projectName}</strong>`



export { home_cute_wolf_nudge } from "./en.js"

export { tiny_pretty_piranha_blink } from "./en.js"

export { hour_hour_manatee_endure } from "./en.js"

export { petty_zany_rook_lend } from "./en.js"

export { only_noisy_koala_beam } from "./en.js"

export { fluffy_shy_oryx_rise } from "./en.js"

export { grassy_round_bird_evoke } from "./en.js"

export { brave_less_reindeer_hike } from "./en.js"

export { calm_novel_jan_reap } from "./en.js"

export { topical_great_puma_surge } from "./en.js"

export { lost_swift_okapi_cook } from "./en.js"

export { grassy_large_warthog_lift } from "./en.js"

export { proud_top_scallop_find } from "./en.js"


	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fresh_tangy_wren_jump = () => `Note (bas de page)`



export { trite_safe_toad_pave } from "./en.js"


	
/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const less_legal_termite_nudge = (params) => `Le ${params.date}`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const muddy_mushy_giraffe_clasp = () => `d LLLL yyyy`



export { patient_solid_marmot_assure } from "./en.js"

export { low_acidic_alpaca_spur } from "./en.js"


	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const alive_glad_marlin_type = () => `Inclure la TVA`



export { fine_blue_poodle_bloom } from "./en.js"

export { wacky_tidy_seahorse_jump } from "./en.js"

export { inclusive_knotty_jackal_mop } from "./en.js"

export { pink_few_tiger_spur } from "./en.js"

export { bold_odd_mole_seek } from "./en.js"

export { soft_kind_lynx_love } from "./en.js"

export { raw_that_mallard_grow } from "./en.js"

export { sea_petty_turtle_honor } from "./en.js"

export { deft_civil_hamster_offer } from "./en.js"


	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const active_spicy_spider_relish = () => `Associé`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bright_tired_yak_exhale = () => `Administrateur`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const main_only_squirrel_earn = () => `Propriétaire`



export { ago_that_lobster_hint } from "./en.js"


	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lime_lofty_firefox_clip = () => `Taille des images`



export { bad_sweet_fly_grin } from "./en.js"

export { last_lazy_felix_dash } from "./en.js"

export { lazy_dull_scallop_build } from "./en.js"

export { good_green_reindeer_vent } from "./en.js"

export { dark_mealy_loris_pray } from "./en.js"